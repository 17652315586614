<template>
    <div class="foot">
        <div class="container">
          <div class="footer_nav">
            <h3 style="color:#fff;margin: 50px 0;">© 2023 JoyBox Studio Limited， All Rights Reserved.</h3>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Footer',
    data(){
      return{
      }
    },
    methods:{
      toAboutUs(){
        this.$router.push({name:"aboutus"}).catch(err => err);
      },
      toCareer(){
        this.$router.push({name:'career'}).catch(err => err);
      }
    },
}
</script>

<style lang="scss" scoped>
.foot {
    width: 100%;
    background-color: #000;
}

.footer {
    padding-top: .2604rem;
    width: 6.25rem;
    margin: 0 auto;
}
.footer_logo{
  padding: .1732rem 0;
  img{
    width: .8542rem;
    height: .6302rem;
  }
}
.footer_nav{
  display: flex;
  justify-content: space-evenly;
}

.iconfont{
  color: #b3b3b3;
  font-size: .1302rem;
}
.footer_icon_ul{
  list-style: none;
  display: flex;
  align-items: center;
  li{
    list-style: none;
    margin-right: .1563rem;
    transition: color .5s ease;
  }
  li:hover{
    color: #ED9A21;
  }
}
.footer_menu{
  text-decoration: none;
  color: #999;
  padding: .2604rem 0;
  li{
    cursor: pointer;
    list-style: none;
    font-size: .0833rem;
    line-height: .2604rem;
    transition: color .5s ease;
  }
  li:hover{
    color: #ED9A21;
  }
}
.footer_menu_ul_li{
  transition: color .5s ease;
  color: #999;
  text-decoration: none;
}
.footer_menu_ul_li:hover{
    color: #ED9A21;
  }
.footer_nav_title{
  line-height: .1563rem;
  font-size: .0938rem;
  color: #000;
  
}
.footer_nav_ul{
  li{
    cursor: pointer;
    list-style: none;
    color: #999;
    font-size: .0833rem;
    line-height: .2604rem;
    transition: color .5s ease;
  }
  li:hover{
    color: #ED9A21;
  }
}
.footer{
  background-image: -webkit-linear-gradient(-30deg,
            transparent 0%,
            transparent 60%,
            rgb(24, 24, 24) 60%,
            rgb(32, 32, 32) 75%,
            transparent 75%,
            transparent);
            margin-top: .2604rem;
}

</style>
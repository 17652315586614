<template>
  <div class="home_body">
    <Header message="/home"></Header>
    <div class="container">

      <div class="game_studio">
        <div class="studio_left wow animate__animated animate__zoomIn">
          <div class="studio_name"><strong>{{ studio_name }}</strong></div>
          <h3 class="studio_declaration">{{ studio_declaration }}</h3>
          <p class="brief_introduction">{{ brief_introduction }}</p>
          <div class="studio_button wow animate__animated animate__rollIn">
            <strong @click="handleSelect('aboutus')">{{ studio_button }}</strong>
          </div>
        </div>
        <div class="studio_right wow animate__animated animate__fadeInLeft">
          <img src="@/assets/images/career.jpg" alt="">
        </div>
      </div>
      <h2 style="color: #fff; text-align: center; font-size: 36px; margin-bottom: 50px;"> Our Games </h2>
      <div style="max-width: 1250px; margin: 0 auto 70px; display:flex; justify-content: space-evenly; flex-wrap: wrap;">
        
        <div style="width: 400px;">
          <img src="../../assets/images/game_icon.png" style="width: 350px; height: 350px;" alt="">
          <div>
            <h2 style="color: #fff; font-size: 24px; margin: 15px 0;">Lovely Monster</h2>
            <p style="color: #fff; font-size: 16px;margin-bottom: 15px;">The player diligently accumulates a multitude of identical low-level monsters through exploration and various in-game activities.</p>
            <el-button type="primary" @click="handleSelect('game')">Learn More</el-button>
          </div>
        </div>
        <div style="width: 400px;">
          <img src="../../assets/images/game_icon2.png" style="width: 350px; height: 350px;" alt="">
          <div>
            <h2 style="color: #fff; font-size: 24px; margin: 15px 0;">GameBox</h2>
            <p style="color: #fff; font-size: 16px;margin-bottom: 15px;">Joybox game box elevates your gaming experience with a diverse collection of games, ranging from casual to challenging puzzles without the need for downloads.</p>
            <el-button type="primary" @click="handleSelect('game')">Learn More</el-button>
          </div>
        </div>
        <div style="width: 400px;">
          <img src="../../assets/images/game_icon3_home.png" style="width: 350px; height: 350px;" alt="">
          <div>
            <h2 style="color: #fff; font-size: 24px; margin: 15px 0;">Basketball Arena</h2>
            <p style="color: #fff; font-size: 16px;margin-bottom: 15px;">The Basketball Arena is a state-of-the-art facility designed to provide an immersive and thrilling basketball experience. </p>
            <el-button type="primary" @click="handleSelect('game')">Learn More</el-button>
          </div>
        </div>
      </div>

      <h2 style="color: #fff; text-align: center; font-size: 36px; margin-bottom: 50px;"> Join Us </h2>
      <div style="max-width: 1250px; margin: 0 auto 70px; display:flex; justify-content: space-evenly; flex-wrap: wrap;">
        <div style="width: 400px;">
          <img src="../../assets/images/join1.png" style="width: 400px; height: 350px;" alt="">
          <div>
            <h2 style="color: #fff; font-size: 24px; margin: 15px 0;">ENGINE PROGRAMMER</h2>
            <p style="color: #fff; font-size: 16px;margin-bottom: 15px;">Strong knowledge of C/C++</p>
            <p style="color: #fff; font-size: 16px;margin-bottom: 15px;">Good knowledge of low level / hardware (CPU, cache, SIMD…) is a plus...</p>
            <el-button type="primary" @click="handleSelect('career')">Learn More</el-button>
          </div>
        </div>
        <div style="width: 400px;">
          <img src="../../assets/images/join2.png" style="width: 400px; height: 350px;" alt="">
          <div>
            <h2 style="color: #fff; font-size: 24px; margin: 15px 0;">ENVIRONMENT ARTIST</h2>
            <p style="color: #fff; font-size: 16px;margin-bottom: 15px;">Create game environment assets: Build, model and texture a variety of environments, architecture, and props according to concepts, references and art direction given to you.</p>
            <el-button type="primary" @click="handleSelect('career')">Learn More</el-button>
          </div>
        </div>
        <div style="width: 400px;">
          <img src="../../assets/images/join3.png" style="width: 400px; height: 350px;" alt="">
          <div>
            <h2 style="color: #fff; font-size: 24px; margin: 15px 0;">GRAPHICS PROGRAMMER</h2>
            <p style="color: #fff; font-size: 16px;margin-bottom: 15px;">Improve and maintain the rendering modules of the game engine</p>
            <p style="color: #fff; font-size: 16px;margin-bottom: 15px;">Develop shaders and quality effects to support the Art direction and unique visual style of the game...</p>
            <el-button type="primary" @click="handleSelect('career')">Learn More</el-button>
          </div>
        </div>
      </div>

    </div>

    <!-- 无限滚动卡片区域 -->
    <div class="scroll_bg">
      <div class="scroll_bg_title" style="color: #ED9A21; font-size: 16px; padding: 30px 0;">TOP PARTNERS</div>
      <div style="padding-bottom: .5208rem;">
        <vue-seamless-scroll :data="images2" :class-option="optionLeft" class="seamless-warp2">
          <ul class="item">
            <li v-for="(item,index) in images2" :key="index">
              <img class="scrollimg" :src="item.url" alt="">
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'HOME',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      studio_name: 'JOYBOX STUDIO LIMITED',
      studio_declaration: 'We are JoyBox Studio Limited',
      brief_introduction: "joybox studio limited，Based in Las Vegas,is a  game development and operation company  currently focusing on casual games. \nOur team is comprised of professionals who have spent years in the game industry .We're channeling our passion and experience into making some of the best games in the world.",
      studio_button:'Find Out More',
      data_display1:'110+',
      data_display2:'2B+',
      data_display3:'10M+',
      awards_right1:'#2 ASIA ',
      awards_right2:'Top Publisher Awards 2022 by AppAnnie',
      awards_right3:'In 2021, Onesoft achieved our goal and was ranked #2 on the Top ANZSEA Publisher Awards by AppAnnie.',
      games_h3:'We unleash great games',
      show_game_p1:'Embark on an adrenaline-fueled adventure with "Zombie Zest: Veggie Warfare"! In this action-packed game, immerse yourself in a world where veggies fight for survival against hordes of ravenous zombies. Strategize, upgrade your arsenal, and unleash epic vegetable-powered attacks to fend off the undead menace. With captivating gameplay and vibrant graphics, "Zombie Zest: Veggie Warfare" promises an addictive gaming experience that will keep you on the edge of your seat!',
      show_game_up_right_title:'Zombie Zest: Veggie Warfare',
      publish_name:'PUBLISHING',
      publish_declaration:"We're leader in publishing games",
      publish_introduction:'Fostering long-term and sustainable relationships, our Publishing, Marketing, and Optimization experts are here to help you create the next hits.',
      publish_button:'START THE GAME',
      career_name:'CAREER',
      career_declaration:'Join Playfunfun Limited Today!',
      career_introduction:"Seize the opportunity to collaborate with creative, like-minded individuals, and together, let's shape the gaming landscape and make an impact on the world.",
      career_button:'JOIN US',

      // bannerGamesImg:[
      //   { url: require("@/assets/images/banner_game1.jpg"),logo: require("@/assets/images/banner_game1_logo.jpg"),title:'Magic Doggy' },
      //   { url: require("@/assets/images/banner_game2.jpg"),logo: require("@/assets/images/banner_game2_logo.jpg"),title:'Roaming Wonderland' },
      //   { url: require("@/assets/images/banner_game3.jpg"),logo: require("@/assets/images/banner_game3_logo.jpg"),title:'Snakey Candy Road' },
      //   { url: require("@/assets/images/banner_game4.jpg"),logo: require("@/assets/images/banner_game4_logo.jpg"),title:'Fighting! Planting!' },
      // ],
      images2: [
        { url: require("@/assets/topPartners/pangle.png") },
        { url: require("@/assets/topPartners/unity.png") },
        { url: require("@/assets/topPartners/vungle.png") },
        { url: require("@/assets/topPartners/tapjoy.png") },
        { url: require("@/assets/topPartners/ggads.png") },
        { url: require("@/assets/topPartners/ggadmob.png") },
        { url: require("@/assets/topPartners/unity.png") },
      ]
    }
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2
      }
    }
  },
  methods:{
    handleSelect(key) {
      this.$router.push({ 'path': key }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);
.game_studio {
  width: 5.9375rem;
  margin-top: .2604rem !important;
  padding: .5208rem 0 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.studio_left>.studio_name{
  font-size: 16px;
  line-height: 25px;
  color: #FB9C2C;
}
.studio_left>.studio_declaration{
  font-size: 36px;
  line-height: 60px;
  color: #fff;
  padding: 0 0 .0521rem;
}
.studio_left>.brief_introduction{
  display: block;
  width: 500px;
  font-size: 16px;
  font-family: '楷书';
  line-height: 30px;
  color: #B3B3B3;
}
.studio_button{
  width: 250px;
  height: 60px;
  background-image: url(@/assets/images/button.png);
  background-size: cover;
  margin-top: .1563rem;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  color: #8F1209;
  cursor: pointer;
}
.studio_left,.studio_right{
  width: 500px;
  padding-bottom: 50px;
}

.data_display{
  width: 5.8854rem;
  margin: 0 auto;
  padding: 0 0 .7813rem ;
  display: flex;
  justify-content: space-evenly;
}
.data_left,.data_center,.data_right{
  display: flex;
  align-items: center
}
.data_left_img{
  width: .2083rem;
  height: .3333rem;
}
.data_left_number{
  margin-left: .1042rem;
  p{
    font-size: 52px;
    line-height: 72px;
    color: #fff;
    font-weight: bold;
  }
  h3{
    color: #B3B3B3;
    font-size: 32px;
    line-height: 42px;
  }
}

// .awards{
//   display: flex;
//   justify-content: center;
// }
// .awards_right{
//   width: 2.8125rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
// }
// .awards_left{
//   text-align: center;
//   margin-right: .2604rem;
// }
// .awards_left_img{
//   width: 1.5208rem;
//   height: 1.2969rem;
// }
// .awards_left_down{
//   width: 2.8125rem;
//   height: .9635rem;
//   background-color: #B3B3B3;
// }
// .awards_right_h2{
//   font-size: .0833rem;
//   color: #FB9C2C;
//   line-height: .1354rem;
// }
// .awards_right_span1{
//   font-size: .1875rem;
//   font-weight: bold;
//   color: #fff;
// }
// .awards_right_span2{
//   font-size: .0833rem;
//   line-height: .1563rem;
//   color: #B3B3B3;
// }

.games{
  width: 5.9375rem;
  margin: .5208rem auto;
}
.games_h2{
  font-size: 16px;
  line-height: 26px;
  color: #FB9C2C;
}
.games_h3{
  font-size: 36px;
  line-height: 62px;
  color: #fff;
}
.display_game{
  display: flex;
}
.display_game_left{
  width: 2.1094rem;
  height: 2.6823rem;
  position: relative;
  top: 0;
  left: 0;
  padding: .1563rem;
}
.display_game_left_img1{
  position: absolute;
  top: 0;
  left: 0;
  width: 2.0833rem;
  height: 2.6823rem;
  margin: .026rem;
  z-index: 1;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.display_game_left_img1:hover{
  opacity: 1;
}
.display_game_left_img2{
  width: 2.0573rem;
  height: 2.6302rem;
  margin: .026rem;
  position: absolute;
  top: .0208rem;
  border-radius: 25px 0 25px 0;
  left: .0156rem;
  cursor: pointer;
}
.show_game{
  height: 2.5521rem;
  width: 1.7188rem;
  position: absolute;
  z-index: 0;
  top: 0;
  left: .2083rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end
}
.show_game_p1{
  font-size: 14px;
  color: #B3B3B3;
  margin: .0521rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:7;
  -webkit-box-orient:vertical;
}
.show_game_p2{
  font-size: 16px;
  color: #fff;
}
.show_game_up{
  display: flex;
  justify-content: space-evenly;
  padding-top: .1042rem;
}
.show_game_up_right{
  padding: 0 0 0 .0521rem;
  height: .3646rem;
}
.show_game_up_right_title{
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  padding: 0 0 .0521rem;
}
.show_game_up_right_p>i{
  margin: 0 0 0 .1563rem;
  color: #ffffdd9e;
}
.show_game_up_right_p_span{
  font-size: 14px;
  color: #B3B3B3;
}
.show_game_up_right_p:last-child{
  font-size: 14px;
  color: #B3B3B3;
}
.show_game_up_left{
  img{
    width: .3906rem;
    height: .3906rem;
    border-radius: .0521rem;
  }
}
// 轮播卡片样式区域
.el-carousel__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: .0781rem;
  background-color: #666 !important;
  transition: background-color 1s ease;
  border-radius: .0781rem;
  .banner_img {
    width: 1.6146rem;
    height: 1.0104rem;
    margin: 0 auto;
    border-radius: .026rem;
  }
}
.el-carousel__item:hover{
  background-color: #FB9C2C !important;
  transition: background-color 1s ease;
  .display_game_card_banner_button{
    background-color: #FB9C2C;
    color: #FB9C2C;
  }
  .display_game_store{
    transition: display 1s ease;
    display: block;
  }
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.display_game_card_banner{
  margin-left: .1042rem;
  width: 3.6458rem;
  height: 2.1875rem;
  padding-top: .2604rem;
}

.display_game_card_banner_button{
  font-size: 30px;
  color: #fff;
  text-align: center;
  background-color: #2c2c2c;
  transition: background-color 1s ease;
  line-height: 65px;
}
.display_game_store{
  display: flex;
}
.display_game_store_img1{
  position: absolute;
  top: -0.0521rem;
  left: .0521rem;
  width: .7604rem;
  height: .224rem;
}
.display_game_store{
  display: none;
  transition: display 1s ease;
}
.display_game_store_img2{
  position: absolute;
  top: -0.0521rem;
  left: .9896rem;
  width: .7604rem;
  height: .224rem;
}
.publish,.career,.game_studio{
  width: 5.9375rem;
  margin: .5208rem auto;
  display: flex;
  justify-content: space-around;
  .studio_right{
    margin-right: .2604rem;
    img{
      width: 2.8073rem;
      height: auto;
    }
  }
  .studio_right_down{
    overflow: hidden;
    background-color: #fff;
    img{
      margin-top: .625rem;
    }
  }
}
/** 滚动区域样式 */
.seamless-warp2 {
  overflow: hidden;
  height: .625rem;
  width: 7.8125rem;
  margin: .2604rem auto;
  padding: .2083rem;
  background-color: #000;
  border-radius: .0521rem;
  border: .0052rem solid #ED9A21;

  ul.item {
    width: 7.0313rem;
    list-style: none;
    li {
      float: left;
      margin-right: .5208rem;
    }
  }
}
.scrollimg{
  width: .4167rem;
  height: auto ;
}
.scroll_bg{
  width: 100vw;
  height: 2.0833rem;
  padding: .2344rem 0;
  background-color: #1a1a1a;
  text-align: center;
}
@media(max-width: 1000){
  .game_studio{
    flex-wrap: wrap;
  }
}
</style>
<template>
  <div style="height: 100%;">
    <Header message="/aboutus"></Header>
    <div class="box">
      <div class="game_studio">
        <div class="about_left">
          <h2>About JoyBox Studio Limited</h2>
          <p>joybox studio limited，Based in Las Vegas,is a game development and operation company currently focusing on casual games. Our team is comprised of professionals who have spent years in the game industry .We’re channeling our passion and experience into making some of the best games in the world.</p>
        </div>
        <img src="../../assets/images/career.jpg" alt="">
      </div>
      <div class="game_studio">
        <img src="../../assets/images/career2.jpg" alt="">

        <div class="about_left">
          <p>We embrace diversity, equity, and inclusion in everything we do – from publishing and operations to fostering safe and respectful workplaces that encourage collaboration. Our culture is based on principles of respect, inclusion, and fair treatment and we welcome anyone into our family without regard to race, religion, gender identity, sexual orientation, or age.</p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'ABOUTUS',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      studio_name: 'About US',
      brief_introduction: "475 S GRAND CENTRAL PKWY, LAS VEGAS, NV 89106, USA",
      images: [ 2018, 2021, 2022 ],
    }
  },
  computed: {
    currentImage() {
      if (this.activeStep==1) {
        return 2018;
      }else if(this.activeStep==2){
        return 2021;
      }else{
        return 2022
      }
    },
    steps_text(){
      return this.steps[this.images.indexOf(this.currentImage)].step_text;
    }
  },
  methods:{
    handleStepChange(newStep) {
      this.activeStep = newStep+1; // Adjust index because change event passes the new step index
    },
    submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                  window.location.href = `mailto:support@joyboxgame.com?subject=dragonfirenet&body=${this.ruleForm}`;
                } else {
                  console.log('error submit!!');
                  return false;
                }
            });
        },
  }
}
</script>

<style lang="scss" scoped>
.game_studio{
    width: 95%;
    margin: 70px auto;
    padding: 100px 0 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .about_left{
      width: 400px;
      h2{
        font-size: 24px;
        margin: 40px 0;
      }
      p{
        font-size: 16px;
        line-height: 30px;
        margin: 40px 0;
      }
    }
    img{
      width: 80%;
      max-width: 600px;
      min-width: 400px;
      border-radius: 10px;
    }
  }
@media (max-width: 900px) {
  .game_studio{
    width: 95%;
    padding-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .about_left{
      width: 400px;
      h2{font-size: 24px;}
      p{font-size: 16px;}
    }
    img{
      width: 400px;
    }
  }
}
@import url(@/fontCss/fontCss.css);
.box{
  h2,p{
    color: #fff;
  }
  .game_studio{
    width: 80%;
    padding-bottom: 100px;
  }
}


</style>
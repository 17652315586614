<template>
  <div>
    <Header message="/career"></Header>
    <div class="career">
      <div class="career_box">
        <div class="join1">
          <img src="../../assets/images/join1.png" alt="">
          <div>
            <h2>ENGINE PROGRAMMER</h2>
            <p>Strong knowledge of C/C++</p>
            <p>Good knowledge of low level / hardware (CPU, cache, SIMD…) is a plus</p>
            <p>Ability to fearlessly jump into large, existing code bases</p>
            <p>Ability to contribute innovative and original ideas towards all aspects of game production and development</p>
            <p>Strong communication and organizational skills</p>
            <p>Knowledge of console de</p>
            <el-button type="primary" @click="handleSelect('contact')">Join Us</el-button>
          </div>
        </div>
        <div class="join2">
          <img src="../../assets/images/join2.png" alt="">
          <div>
            <h2>ENVIRONMENT ARTIST</h2>
            <p>Create game environment assets: Build, model and texture a variety of environments, architecture, and props according to concepts, references and art direction given to you.</p>
            <p>Create realistic PBR textures and materials to compliment the games art direction.</p>
            <p>Use existing assets and content to populate the game world in a believable and visually appealing way.</p>
            <p>Build the environments according to Level design requirements and blockouts, bearing gameplay constraints such as traversal and metrics in mind.</p>
            <p>Work alongside your senior colleagues, art director and leads to continually revise game environments and refine visual quality, storytelling, and drama.</p>
            <p>Take relevant memory and performance restrictions into consideration.</p>
            <el-button type="primary" @click="handleSelect('contact')">Join Us</el-button>
          </div>
        </div>
        <div class="join3">
          <img src="../../assets/images/join3.png" alt="">
          <div>
            <h2>GRAPHICS PROGRAMMER</h2>
            <p>Improve and maintain the rendering modules of the game engine</p>
            <p>Develop shaders and quality effects to support the Art direction and unique visual style of the game</p>
            <p>Optimize the rendering code to get the best performance on all platforms</p>
            <p>Write clear, maintenable, portable C++ code</p>
            <p>Write and maintain custom shaders across a range of hardware</p>
            <p>Work well with other engineers, artists and designers</p>
            <el-button type="primary" @click="handleSelect('contact')">Join Us</el-button>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'CAREER',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      life1:'We are JOYBOX STUDIO LIMITED',
      life2:'Join JOYBOX STUDIO LIMITED Today!',
      life3:'We cultivate a forward-thinking, dynamic, and imaginative atmosphere, providing you with the freedom to craft your ideal games.',
      img_index:'1',
    }
  },
  computed: {
    optionLeft() {
      return {
      }
    }
  },
  methods:{
    changeImg(index){
      this.img_index = index + 1;
    },
    handleSelect(key) {
      this.$router.push({ 'path': key }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);

.career{
  padding-top: .3646rem;
  width: 100%;
  height: 100%;
  background-color: #222;
}

.career_down{
  width: 100%;
  height: 250px;
  padding-top: .2604rem;
  background-color: #000;
}
.careers{
  width: 5.9375rem;
  margin: 0 auto;
  h2{
    color: #FB9C2C;
    font-size: .0833rem;
    line-height: .1354rem;
  }
  h3{
    font-size: .1875rem;
    line-height: .3229rem;
    padding: 0 0 .0521rem;
    color: #fff;
  }
}
.career{
  .career_box{
    max-width: 1250px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .join1,.join2,.join3{
      width: 80%;
      min-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 50px 0;
      img{
        border-radius: 10px;
        width: 80%;
        height: auto;
        min-width: 400px;
      }
      h2,p{
        color: #fff;
      }
      h2{
        font-size: 36px;
      }
      p{
        font-size: 16px;
        line-height: 30px;
      }
      div{
        margin: 30px 0;
        width: 80%;
        min-width: 400px;
      }
    }
  }
}

/** 滚动区域样式 */
.scroll_bg_title{
  color: #fff;
  font-size: .1667rem;
  padding: .1563rem 0;

}
.seamless-warp2 {
  overflow: hidden;
  height: .625rem;
  width: 5.2083rem;
  margin: .2604rem auto;
  padding: .0521rem;
  background-color: #000;
  position: relative;
  top: 0;
  left: .3646rem;
  ul.item {
    width: 5.2083rem;
    list-style: none;
    li {
      float: left;
      margin-right: .4167rem;
    }
  }
}
.scrollimg{
  width: .7813rem;
  height: auto ;
}
</style>
<template>
  <div>
    <Header message="/game"></Header>
    <div class="container">
      <div class="bannerbox">
        <!-- 轮播图-游戏展示区域 -->
        <div class="featured"  data-wow-duration="2s" style="display: flex;">
          <h3 style="font-size: 48px; color: #fff;">Featured Game</h3>
          <a href=""><span>View all</span><i style="display: block; border-radius: 100; color: #Ed9A21;"
              class="el-icon-right" /></a>
        </div>
        
        <div class="gameBox wow animate__animated animate__zoomInUp" style="margin: 150px auto 0; display: flex; flex-wrap: wrap; width: 80%; min-width: 400px; justify-content: space-between;">
          <div style=" display: flex; align-items: center; justify-content: space-evenly; width: 80%;">
            <img src="../../assets/images/game_icon.png" style="display: block; width: 400px;" alt="">
            <div style="color: #fff; width: 400px;">
              <h2 style="font-size: 36px; margin-bottom: 30px;">Lovely Monster</h2>
              <p style="font-size: 16px; color: #c3c3c3;">The player diligently accumulates a multitude of identical low-level monsters through exploration and various in-game activities.</p>
              <p style="font-size: 16px; color: #c3c3c3;">As their collection grows, they embark on a strategic journey of merging and synthesizing these creatures, melding their unique attributes and capabilities to forge formidable, higher-level monsters of unparalleled strength and prowess.</p>
              <a href="http://game.joyboxgame.com" target="_blank">
                <el-button style="font-size: 16px;margin-top: 30px;" size="mini">START THE GAME <i style="font-size: 15px;" class="iconfont icon-dianji"></i></el-button>
              </a>
            </div>
          </div>
          <div></div>
        </div>
        <div class="gameBox wow animate__animated animate__zoomInUp" style="margin: 30px auto; display: flex; flex-wrap: wrap; width: 80%; min-width: 400px; justify-content: space-between;">
          <div style=" display: flex; align-items: center; justify-content: space-evenly; width: 80%;">
            <img src="../../assets/images/game_icon2.png" style="display: block; width: 400px;" alt="">
            <div style="color: #fff; width: 400px;">
              <h2 style="font-size: 36px; margin-bottom: 30px;">GameBox</h2>
              <p style="font-size: 16px; color: #c3c3c3;">Joybox game box elevates your gaming experience with a diverse collection of games, ranging from casual to challenging puzzles without the need for downloads.</p>
              <p style="font-size: 16px; color: #c3c3c3;">You can play anytime, anywhere, and even invite friends for social gaming fun.</p>
              <p style="font-size: 16px; color: #c3c3c3;">It's a must-try platform for gaming enthusiasts!</p>
              <a href="https://gamebox.joyboxgame.com/home" target="_blank">
                <el-button style="font-size: 16px;margin-top: 30px;" size="mini">START THE GAME <i style="font-size: 15px;" class="iconfont icon-dianji"></i></el-button>
              </a>
            </div>
          </div>
          <div></div>
        </div>
        <div class="gameBox wow animate__animated animate__zoomInUp" style="margin: 30px auto; display: flex; flex-wrap: wrap; width: 80%; min-width: 400px; justify-content: space-between;">
          <div style=" display: flex; align-items: center; justify-content: space-evenly; width: 80%;">
            <img src="../../assets/images/game_icon3.png" style="display: block; width: 400px;" alt="">
            <div style="color: #fff; width: 400px;">
              <h2 style="font-size: 36px; margin-bottom: 30px;">Basketball Arena</h2>
              <p style="font-size: 16px; color: #c3c3c3;">The Basketball Arena is a state-of-the-art facility designed to provide an immersive and thrilling basketball experience. With its spacious court, comfortable seating, and top-notch lighting and sound systems, this arena offers the perfect setting for both professional and amateur basketball games. The arena’s modern design ensures excellent visibility from every seat, allowing spectators to fully enjoy the fast-paced action on the court. Whether you’re a player or a fan, the Basketball Arena is the ultimate destination for all basketball enthusiasts.</p>
              <a style="cursor: pointer;" target="_blank">
                <el-button style="font-size: 16px;margin-top: 30px;" size="mini">Under development</el-button>
              </a>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
    name:'GAME',
    components: {
    Header,
    Footer,
  },
  data() {
    return {
      images: [
          { 
            url: require("@/assets/images/banner_1.png"),
            name:'Lovely Monster',
            number:'',
            logo:require("@/assets/images/game_icon.png"),
            gameText:'The player diligently accumulates a multitude of identical low-level monsters through exploration and various in-game activities. As their collection grows, they embark on a strategic journey of merging and synthesizing these creatures, melding their unique attributes and capabilities to forge formidable, higher-level monsters of unparalleled strength and prowess.' ,
            hrefUrl:"http://game.joyboxgame.com",
          },
          { 
            url: require("@/assets/images/banner_1.png"),
            name:'GameBox',
            number:'',
            logo:require("@/assets/images/game_icon2.png"),
            gameText: "Joybox game box elevates your gaming experience with a diverse collection of games, ranging from casual to challenging puzzles without the need for downloads. You can play anytime, anywhere, and even invite friends for social gaming fun. It's a must-try platform for gaming enthusiasts!",
            hrefUrl:"https://gamebox.joyboxgame.com/home",
          },
        ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);

.banner_box{
  position: absolute;
  top: 50%;
  left: .9375rem;
  width: 5.9375rem;
  display: flex;
  .banner_box_right{
    position: absolute;
    z-index: 9;
    top: -20px;
    left: 640px;
    // img{
    //   cursor: pointer;
    //   width: 1.1354rem;
    //   height: .3333rem;
    // }
  }
}
.banner_logo{
  width: .6667rem;
  height: .6667rem;
}
.show_game{
  height: 2.5521rem;
  width: 1.7188rem;
  position: absolute;
  z-index: 9;
  top: -380px;
  left: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end
}
.show_game_p1{
  font-size: .0729rem;
  color: rgb(233, 233, 233);
  margin: .0521rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical;
}
.show_game_p2{
  font-size: .0833rem;
  color: #fff;
}
.show_game_up{
  display: flex;
  justify-content: space-evenly;
  padding-top: .1042rem;
}
.show_game_up_right{
  padding: 0 0 0 .0521rem;
  height: .3646rem;
}
.show_game_up_right_title{
  color: #fff;
  font-size: .1563rem;
  line-height: .1354rem;
  padding: 0 0 .0521rem;
  width: 1.25rem;
  line-height: .1823rem;
}
.show_game_up_right_p>i{
  margin: 0 0 0 .1563rem;
  color: #ffffdd9e;
}
.show_game_up_right_p_span{
  font-size: .0729rem;
  color: #d5d3d3;
}
.show_game_up_right_p:last-child{
  font-size: .0729rem;
  color: #d5d3d3;
}
.show_game_up_left{
  width: 100px;
  height: 100px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 100%;
    height: auto;
  }
}
.showgame{
  width: 5.9375rem;
  margin: 0 auto;
}



.showgame_tit{
  display: flex;
  justify-content: space-evenly;
  margin-bottom: .1563rem;
  .showgame_tit_left{
    h2{
      font-size: .2083rem;
      color: #FB9C2C;
    }
    p{
      font-size: .0833rem;
      color: #B3B3B3;
      width: 3.125rem;
    }
  }
}
.data_left,.data_center,.data_right{
  display: flex;
  align-items: center
}
.data_left_img{
  width: .2083rem;
  height: .3333rem;
}
.data_left_number{
  margin-left: .1042rem;
  p{
    font-size: .2708rem;
    line-height: .3802rem;
    color: #fff;
    font-weight: bold;
  }
  h3{
    color: #B3B3B3;
    font-size: .1667rem;
    line-height: .2188rem;
  }
}
.showgame_box{
  width: 5.9375rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  border: .0052rem solid #FB9C2C;
  border-radius: .0521rem;
}
.game_item{
  width: 1.3281rem;
  height: 1.8906rem;
  border-radius: .0521rem;
  margin: .0521rem .0729rem;
  background-color: #fff;
  text-align: center;
  position: relative;
  .game_item_bgimg{
    border-radius: .0521rem;
    width: 100%;
    height: auto;
    opacity: .3;
  }
  .game_item_name{
    margin-top: .1563rem;
    cursor: pointer;
    font-size: .1042rem;
    color: #fff;
    transition: color .3s ease;
  }
  .game_item_name:hover{
    color: #FB9C2C;
  }
  .game_item_hover{
    position: absolute;
    top: .5729rem;
    left: .3906rem;
  }
  .game_item_hoverimg1{
    width: .2604rem;
    height: .2604rem;
    border-radius: .026rem;
  }
  .game_item_hoverimg2{
    width: .5208rem;
    height: auto;
  }
  .game_item_hoverp{
    color: #fff;
    margin: .0521rem 0;
  }
}

</style>
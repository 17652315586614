<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app{
  background-color: #000;
  width: 100%;
  min-height: 100vh;
}
::-webkit-scrollbar {
width: 0 !important;
}
</style>
